import { VariantViewModel } from 'framework/common/variantViewModel'
import { useMemo } from 'react'
import { UseVariantDataResult } from './use-variant-data'
import { EnterpriseVariantData } from 'framework/enterprise/server/productData'

interface PreOrder {
  isPreOrder: boolean
  estimatedDeliveryDays: number
}

const estimatedDeliveryDays = (d: EnterpriseVariantData) =>
  d.stock.inStock < 1 && d.stock.preOrder > 0
    ? d.stock.estimatedDeliveryDays
    : 0

/**
 * Checks if any of the codes are pre order
 * @param codes
 * @param vData
 * @returns
 */
export const usePreOrder = (
  codes: string[],
  vData: UseVariantDataResult,
): PreOrder =>
  useMemo(() => {
    const preOrder: PreOrder = { isPreOrder: false, estimatedDeliveryDays: 0 }
    for (const code of codes) {
      const data = vData.data[code]
      if (!data || 'noresult' in data) continue

      const e = data ? estimatedDeliveryDays(data) : 0
      if (e) {
        preOrder.isPreOrder = true
        preOrder.estimatedDeliveryDays = Math.max(
          e,
          preOrder.estimatedDeliveryDays,
        )
      }
    }
    return preOrder
  }, [codes, vData])

interface Price {
  price: number
  comparePrice?: number
  vatIncluded?: boolean
  loading?: boolean
  currency: string
}

interface Stock {
  inStock: number
  preOrder: number
  estimatedDeliveryDays: number
  loading?: boolean
}

export const toComparePrice = (
  price: number,
  campaignPrice?: number | null,
): [number, number | undefined] => {
  if (campaignPrice && campaignPrice < price) {
    return [campaignPrice, price]
  }
  return [price, undefined]
}

const getPrice = (
  code: string,
  vData: UseVariantDataResult,
): Price | { loading: true } | null => {
  const r: Price = {
    price: 0,
    currency: '',
  }
  if (vData.isLoading) return { loading: true }

  const d = vData.data[code]
  if (!d || 'noresult' in d) {
    return null
  }

  r.currency = d.currency
  r.vatIncluded = d.vatIncluded
  const [price, comparePrice] = toComparePrice(d.price, d.campaignPrice)
  r.price += price
  r.comparePrice =
    comparePrice === undefined
      ? r.comparePrice
      : r.comparePrice === undefined
        ? comparePrice
        : r.comparePrice + comparePrice

  return r
}

export const usePrice = (
  code: string,
  vData: UseVariantDataResult,
): Price | { loading: true } | null => {
  return useMemo(() => getPrice(code, vData), [code, vData])
}

export const useStock = (
  code: string,
  vData: UseVariantDataResult,
): Stock | { loading: true } => {
  return useMemo(() => {
    if (vData.isLoading) return { loading: true }
    const r: Stock = { inStock: 0, preOrder: 0, estimatedDeliveryDays: 0 }
    const v = vData.data[code]
    if (!v || 'noresult' in v) {
      return r
    }
    r.inStock = v.stock.inStock
    r.estimatedDeliveryDays = v.stock.estimatedDeliveryDays
    r.preOrder = v.stock.preOrder
    return r
  }, [code, vData])
}

interface StockResult {
  inStock: boolean
  loading?: boolean
  estimatedDeliveryDays: number
}

/**
 * Helper function to determine if an array of codes are in stock based on the variant data
 * Useful for checking if the full cart is in stock or not
 * @param codes Variant codes to check against
 * @param vData Variant data from enterprise
 * @returns StockResult
 */
export const isInStock = (
  codes: string[],
  vData: UseVariantDataResult,
): StockResult | { loading: true } => {
  if (vData.isLoading) return { loading: true }
  const r: StockResult = { inStock: true, estimatedDeliveryDays: 0 }
  for (const code of codes) {
    const v = vData.data[code]

    if (!v || 'noresult' in v)
      return { inStock: false, estimatedDeliveryDays: 0 }

    if (v.stock.inStock === 0) {
      if (v.stock.preOrder > 0) {
        r.estimatedDeliveryDays = Math.max(
          r.estimatedDeliveryDays,
          v.stock.estimatedDeliveryDays,
        )
      } else {
        return { inStock: false, estimatedDeliveryDays: 0 }
      }
    }
  }
  return r
}

/**
 * Hook for isInStock
 * @see isInStock
 **/
export const useIsInStock = (codes: string[], vData: UseVariantDataResult) =>
  useMemo(() => isInStock(codes, vData), [codes, vData])

export const useProductLabel = (
  variant: VariantViewModel,
  vData: UseVariantDataResult,
) => {
  return useMemo(() => {
    if (!variant.availableOnline) return ''

    const v = vData.data[variant.code]

    if (v && !('noresult' in v)) {
      if (variant.product.discontinued && v.stock.inStock === 0) {
        return 'discontinued'
      }
      if (v.campaignPrice && v.stock.inStock > 0) {
        return 'sale'
      }
    }
    const product = variant.product
    if (product.limitedEdition) {
      return 'limitedEdition'
    }
    if (product.newArrival) {
      return 'newArrival'
    }
    if (product.commingSoon) {
      return 'comingSoon'
    }
    return ''
  }, [vData, variant])
}

/**
 * Helper function to determine if a product is discontinued
 */
const isAbsoluteDiscontinued = (
  variant: VariantViewModel,
  vData: UseVariantDataResult,
) => {
  const v = vData.data[variant.code]
  return (
    v &&
    !('noresult' in v) &&
    v.stock.inStock === 0 &&
    variant.product.discontinued
  )
}

/**
 * Hook for isAbsoluteDiscontinued
 * @see isAbsoluteDiscontinued
 */
export const useIsAbsoluteDiscontinued = (
  variant: VariantViewModel,
  vData: UseVariantDataResult,
) => useMemo(() => isAbsoluteDiscontinued(variant, vData), [variant, vData])
