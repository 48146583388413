import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { isInStock, toComparePrice } from './utils'
import { useEffect, useMemo } from 'react'
import { getImageUrl4x5 } from 'utils/image'
import isInChannel from 'utils/isInChannel'
import { useAppSelector } from 'store'
import type { EnterpriseVariantStockData } from 'framework/enterprise/server/productData'
import { useVariantData } from './use-variant-data'
import { useVariantInfo } from './use-variant-info'
import {
  TrackAddToCart,
  TrackAddToQuote,
} from 'framework/tracking/common/events'
import shouldDebugAnalytics from 'utils/shouldDebugAnalytics'
import { useRouter } from 'next/router'

const DATA_VERSION = 1

type BaseItemOrLoading = BaseItem | LoadingBaseItem

export interface BaseCart<T extends BaseItemOrLoading = BaseItemOrLoading> {
  country: string
  currency: string
  quantity: number
  items: T[]
}

export interface LoadingBaseItem {
  code: string
  quantity: number
  loading: true
}

export interface BaseItem {
  code: string
  quantity: number
  name: string
  desc?: string
  loading: false
  img: string | null
  path: string
  isAvailableOnline: boolean
  isFreeGift: boolean
}

export interface FullCart extends BaseCart<FullCartItem | LoadingBaseItem> {
  subTotal: number
  comparePrice?: number
  estimatedDeliveryDays: number
  inStock: boolean
  loading: boolean
}

export const initialCart: FullCart = {
  country: '',
  currency: '',
  quantity: 0,
  subTotal: 0,
  comparePrice: 0,
  estimatedDeliveryDays: 0,
  inStock: false,
  items: [],
  loading: true,
}

export interface FullCartItem extends BaseItem {
  price: number
  comparePrice?: number
  currency: string
  stock: EnterpriseVariantStockData
}

export const initialFavorites: BaseCart = {
  country: '',
  currency: '',
  items: [],
  quantity: 0,
}

type CartItem = {
  quantity: number
}
type CartStore = {
  items: Record<string, CartItem>
  favorites: Record<string, CartItem>
  addToCart: (code: string, quantity?: number, type?: CartType) => void
  updateCart: (code: string, quantity: number, type?: CartType) => void
  removeFromCart: (code: string, quantity?: number, type?: CartType) => void
  removeAllFromCart: (code: string, type?: CartType) => void
  clearCart: (type?: CartType) => void
  moveFavoriteItemsToCart: () => void
  moveCartItemsToFavorite: () => void
  removalTriggered: boolean
  resetRemovalTriggered: () => void
}

type CartType = 'items' | 'favorites'
const store = immer<CartStore>((set) => ({
  items: {},
  favorites: {},
  removalTriggered: false,
  resetRemovalTriggered: () => {
    set((s) => {
      s.removalTriggered = false
    })
  },
  addToCart: (code: string, quantity = 1, cartType: CartType = 'items') => {
    set((s) => {
      if (quantity > 0) {
        if (!s[cartType][code]) {
          s[cartType][code] = { quantity }
        } else {
          s[cartType][code].quantity += quantity
        }
      }
    })
  },
  removeFromCart: (
    code: string,
    quantity = 1,
    cartType: CartType = 'items',
  ) => {
    set((s) => {
      if (quantity > 0) {
        if (s[cartType][code]) {
          s[cartType][code].quantity -= quantity
          if (s[cartType][code].quantity <= 0) {
            delete s[cartType][code]
          }
          s.removalTriggered = true
        }
      }
    })
  },
  updateCart: (
    code: string,
    quantity: number,
    cartType: CartType = 'items',
  ) => {
    set((s) => {
      if (quantity <= 0) {
        delete s[cartType][code]
      } else {
        s[cartType][code] = { quantity }
      }
    })
  },
  removeAllFromCart: (code: string, cartType: CartType = 'items') => {
    set((s) => {
      delete s[cartType][code]
      s.removalTriggered = true
    })
  },
  clearCart: (cartType: CartType = 'items') => {
    set((s) => {
      s[cartType] = {}
    })
  },
  moveFavoriteItemsToCart: () => {
    set((s) => {
      for (const [code, f] of Object.entries(s.favorites)) {
        if (s.items[code]) {
          s.items[code].quantity += f.quantity
        } else {
          s.items[code] = { quantity: f.quantity }
        }
      }
      // Clear favorites
      s.favorites = {}
    })
  },
  moveCartItemsToFavorite: () => {
    set((s) => {
      for (const [code, f] of Object.entries(s.items)) {
        if (s.favorites[code]) {
          s.favorites[code].quantity += f.quantity
        } else {
          s.favorites[code] = { quantity: f.quantity }
        }
      }
      // Clear cart
      s.items = {}
    })
  },
}))

const persidedStore = persist(store, {
  name: 'eleiko-cart',
  version: DATA_VERSION,
})

const useCartStore = create<CartStore>()(
  devtools(persidedStore, { name: 'eleiko-cart' }),
)

export const useCart = () => {
  const {
    items,
    favorites,
    removeAllFromCart,
    updateCart,
    addToCart: _addToCart,
    ...rest
  } = useCartStore()
  const { locale, currency } = useAppSelector((s) => s.channel)
  const router = useRouter()

  // We only need to fetch the stock and price data for the items in the cart
  const itemArray = useMemo(() => Object.keys(items), [items])
  const vData = useVariantData(itemArray)
  const cartVariants = useVariantInfo(itemArray)

  const favoritesArray = useMemo(() => Object.keys(favorites), [favorites])
  const favoriteVariants = useVariantInfo(favoritesArray)

  const fullCart = useMemo(() => {
    const stock = isInStock(itemArray, vData)

    const totalPrice = {
      subTotal: 0,
      comparePrice: 0,
    }
    let absoluteCurrency = ''
    const cartItems: (FullCartItem | LoadingBaseItem)[] = []
    for (const i of itemArray) {
      const item = items[i]
      if (!item) {
        console.error('Item not found')
        continue
      }

      if (vData.isLoading || cartVariants.isLoading) {
        cartItems.push({
          code: i,
          quantity: item.quantity,
          loading: true,
        })
        continue
      }

      // If we could not fetch data for this item, just continue.
      const v = cartVariants.data[i]
      if (!v || 'noresult' in v) {
        continue
      }

      // If we could not fetch data for this item, just continue.
      const vd = vData.data[i]
      if (!vd || 'noresult' in vd) {
        continue
      }

      const [price, comparePrice] = toComparePrice(vd.price, vd.campaignPrice)

      if (vd.campaignPrice) {
        totalPrice.subTotal += vd.campaignPrice * item.quantity
        totalPrice.comparePrice += vd.price * item.quantity
      } else {
        totalPrice.subTotal += vd.price * item.quantity
      }

      const [name, desc] = v.name.split(' - ')

      const ii: FullCartItem = {
        code: v.code,
        quantity: item.quantity,
        name: name?.replace('Eleiko ', '') ?? '',
        desc,
        img: v.images?.[0]?.name ? getImageUrl4x5(v.images[0].name) : null,
        path: v.path,
        loading: false,
        isFreeGift: false,
        isAvailableOnline: isInChannel({
          locale,
          text: v.availableOnline,
          defaultIfEmpty: true,
        }),
        price,
        comparePrice,
        stock: vd.stock,
        currency: vd.currency,
      }
      absoluteCurrency = vd.currency
      cartItems.push(ii)
    }

    const _fullCart: FullCart = {
      ...initialCart,
      items: cartItems,
      subTotal: totalPrice.subTotal,
      comparePrice:
        totalPrice.comparePrice > totalPrice.subTotal
          ? totalPrice.comparePrice
          : undefined,
      estimatedDeliveryDays: stock.loading ? 0 : stock.estimatedDeliveryDays,
      inStock: stock.loading ? false : stock.inStock,
      loading: stock.loading || false,
      currency: absoluteCurrency,
    }
    return _fullCart
  }, [cartVariants, itemArray, items, locale, vData])

  const fullFavorites = useMemo(() => {
    const favoriteItems: (BaseItem | LoadingBaseItem)[] = []
    for (const i of favoritesArray) {
      const item = favorites[i]
      if (!item) {
        console.error('Item not found')
        continue
      }

      if (favoriteVariants.isLoading) {
        favoriteItems.push({
          code: i,
          quantity: item.quantity,
          loading: true,
        })
        continue
      }

      // If we could not fetch data for this item, just continue.
      const v = favoriteVariants.data[i]
      if (!v || 'noresult' in v) {
        continue
      }

      const [name, desc] = v.name.split(' - ')
      favoriteItems.push({
        code: v.code,
        quantity: item.quantity,
        name: name?.replace('Eleiko ', '') ?? '',
        desc: desc,
        img: v.images?.[0]?.name ? getImageUrl4x5(v.images[0].name) : null,
        path: v.path,
        loading: false,
        isFreeGift: false,
        isAvailableOnline: isInChannel({
          locale,
          text: v.availableOnline,
          defaultIfEmpty: false, // v.availableOnline is empty if hidePrices in all channels
        }),
      })
    }

    const _fullFavorites: BaseCart = {
      ...initialFavorites,
      items: favoriteItems,
    }
    return _fullFavorites
  }, [favoriteVariants, favorites, favoritesArray, locale])

  // Check if there's stock for the items in the cart
  // If not, remove the item from the cart
  useEffect(() => {
    for (const i of itemArray) {
      const item = items[i]
      if (!item) {
        continue
      }
      if (vData.isLoading) {
        continue
      }

      const vd = vData.data[i]
      // For some reason we could not fetch data for this item. Just continue but log a warning
      if (!vd || 'noresult' in vd) {
        continue
      }
      const allStocks = vd.stock.inStock + vd.stock.preOrder

      // If there's no stock, remove the product from the cart
      if (allStocks === 0) {
        removeAllFromCart(i, 'items')
        continue
      }

      // If there's stock, but less than selected quantity, decrease the quantity to stock level
      if (allStocks < item.quantity) {
        // Decrease
        updateCart(i, allStocks)

        // Attention, here we are mutating the state directly do prevent another rerendering.
        // We are already in the useEffect, so it's safe to do so as long as we update the state after.
        //item.quantity = allStocks
      }
    }
  }, [itemArray, items, removeAllFromCart, updateCart, vData])

  // Add to Cart with Tracking
  const addToCart = (code: string, quantity = 1, type: CartType = 'items') => {
    _addToCart(code, quantity, type)
    const vd = vData.data[code]
    const v = cartVariants.data[code]

    // Try to get price of the variant
    let price = 0
    if (!vd || 'noresult' in vd) {
      // Do nothing
    } else {
      price = vd.price
    }

    // Try to get the name of the variant
    let name = ''
    if (!v || 'noresult' in v) {
      // Do nothing
    } else {
      name = v.name
    }

    if (type === 'items') {
      TrackAddToCart({
        currency,
        price,
        items: [
          {
            id: code,
            name: name,
            price,
            quantity,
          },
        ],
        debug: shouldDebugAnalytics(router),
      })
    } else {
      TrackAddToQuote({
        currency,
        price,
        items: [
          {
            id: code,
            name: name,
            price,
            quantity,
          },
        ],
        debug: shouldDebugAnalytics(router),
      })
    }
  }

  return {
    cart: fullCart,
    favorites: fullFavorites,
    removeAllFromCart,
    updateCart,
    addToCart,
    ...rest,
  }
}
