// Calls Enterprise and return a map of variantId to variant data
import { z } from 'zod'

import { fetchJson } from 'utils/fetchJson'

const zEnterpriseVariantStockData = z.object({
  // If any stock value is negative, it should be treated as 0
  inStock: z.number().min(0).catch(0),
  preOrder: z.number().min(0).catch(0),
  // If estimatedDeliveryDays is negative, it should be treated as 0
  estimatedDeliveryDays: z.number().min(0).catch(0),
})

export type EnterpriseVariantStockData = z.infer<
  typeof zEnterpriseVariantStockData
>

const zEnterpriseVariantData = z.object({
  variantId: z.string(),
  // Price has to be at least 1
  price: z.number(),
  // CampaignPrice must be above 0, but can be null if there is no campaign
  campaignPrice: z.number().transform((num) => (num > 0 ? num : null)),
  currency: z.string(),
  vatIncluded: z.boolean(),
  stock: zEnterpriseVariantStockData,
  thirtyDayLowestPrice: z.number(),
})

export type EnterpriseVariantData = z.infer<typeof zEnterpriseVariantData>

export type EnterpriseVariantDataEmpty = {
  variantId: string
  noresult: true
  reason: 'no-record' | 'no-price'
}

const zEnterpriseVariantDataResponse = z.record(zEnterpriseVariantData)

export const getEnterpriseVariantData = async (
  variantIds: string[],
  country: string,
  channel: string,
  sessionId?: string, // use in upsell to get the discounted price on checkout
) => {
  const ids = Array.isArray(variantIds) ? variantIds : [variantIds]
  const r = await fetchJson(
    `https://enterprise.eleiko.com/webshopapi/product/getrealtimeproductdata?variantIds=${ids.join(
      ',',
    )}&country=${country}&channel=${channel}&sessionId=${sessionId}`,
  )

  const data = zEnterpriseVariantDataResponse.parse(r)

  // Go through the data and make sure there is a record for every variantId otherwise add a null record.
  const result: Record<
    string,
    EnterpriseVariantData | EnterpriseVariantDataEmpty
  > = {}
  ids.forEach((id) => {
    result[id] = data[id]
    if (!result[id]) {
      console.warn(
        `No price and stock was found for item: ${id} reason: no-record`,
      )
      result[id] = {
        variantId: id,
        noresult: true,
        reason: 'no-record',
      }
    }
  })

  // Remove all records that has price set to 0, (Issue from enterprise API).
  for (const [key, value] of Object.entries(result)) {
    // Skip if no result
    if ('noresult' in value) continue

    // If price is 0, remove the record
    if (value.price === 0) {
      console.warn(
        `No price and stock was found for item: ${key} reason: no-price`,
      )
      result[key] = { variantId: key, noresult: true, reason: 'no-price' }
    }
  }
  return result
}
