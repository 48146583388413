import { useQueries } from '@tanstack/react-query'
import { create, indexedResolver, windowScheduler } from '@yornaath/batshit'
import {
  VariantInfo,
  VariantInfoEmpty,
  getVariantInfo,
} from 'framework/strapi/server/variantInfo'
import { useMemo } from 'react'

const useFetcher = () => {
  const fetcher = useMemo(
    () =>
      create({
        // The fetcher resolves the list of queries(here just a list of user ids as number) to one single api call.
        fetcher: async (codes: string[]) => {
          return getVariantInfo(codes)
        },
        // when we call users.fetch, this will resolve the correct user using the field `id`
        resolver: indexedResolver(),
        // this will batch all calls to users.fetch that are made within 10 milliseconds.
        scheduler: windowScheduler(10),
      }),
    [],
  )
  return fetcher
}

export type UseVariantInfoResultData = Record<
  string,
  VariantInfo | VariantInfoEmpty
>

export type UseVariantInfoResult = {
  data: UseVariantInfoResultData
  isLoading: boolean
}

export const useVariantInfo = (codes: string[]): UseVariantInfoResult => {
  const fetcher = useFetcher()

  const queries = useQueries({
    queries: codes.map((code) => {
      return {
        enabled: !!code,
        queryKey: ['variant', 'info', code],
        queryFn: () => fetcher.fetch(code),
      }
    }),
    combine: (results) => {
      const data = {} as UseVariantInfoResultData
      for (const result of results) {
        if (result.data) {
          data[result.data.code] = result.data
        }
      }
      return { data, isLoading: results.some((r) => r.isLoading) }
    },
  })

  return queries
}
