import { fetchJson } from 'utils/fetchJson'
import z from 'zod'

const zVariant = z.object({
  code: z.string(),
  name: z.string(),
  ts: z.number(),
  path: z.string(),
  desc: z.string(),
  images: z.array(
    z.object({ name: z.string(), caption: z.string().optional() }),
  ),
  availableOnline: z.string(),
})

const zVariants = z.record(zVariant.nullable())

export type VariantInfo = z.infer<typeof zVariant>
export type VariantInfoEmpty = {
  code: string
  noresult: true
  reason: 'no-record'
}

export const getVariantInfo = async (codes: string[]) => {
  const d = await fetchJson(
    `${
      process.env.NEXT_PUBLIC_CMS_API_URL
    }/eleiko/variantinfo?codes=${codes.join(',')}`,
  )
  const data = zVariants.parse(d)

  // Go through the data and make sure there is a record for every variantId otherwise add a null record.
  const result: Record<string, VariantInfo | VariantInfoEmpty> = {}
  codes.forEach((id) => {
    result[id] = data[id] || { code: id, noresult: true, reason: 'no-record' }
  })
  return result
}
