export const fetchJson = async <T>(
  input: RequestInfo | URL,
  init?: RequestInit,
) => {
  console.log('fetchJson', input)
  const r = await fetch(input, init)
  const text = await r.text()
  if (!r.ok) {
    throw new Error(`Failed to fetch ${r.statusText} ${text}`)
  }
  try {
    return JSON.parse(text) as T
  } catch {
    throw new Error(`Failed to parse response: ${text}`)
  }
}
