import { useQueries } from '@tanstack/react-query'
import { create, indexedResolver, windowScheduler } from '@yornaath/batshit'
import {
  EnterpriseVariantDataEmpty,
  EnterpriseVariantData,
  getEnterpriseVariantData,
} from 'framework/enterprise/server/productData'
import { useMemo } from 'react'
import { useAppSelector } from 'store'

const useFetcher = (country: string, locale: string) => {
  const fetcher = useMemo(
    () =>
      create({
        // The fetcher resolves the list of queries(here just a list of user ids as number) to one single api call.
        fetcher: async (codes: string[]) => {
          return getEnterpriseVariantData(codes, country || 'us', locale)
        },
        // when we call users.fetch, this will resolve the correct user using the field `id`
        resolver: indexedResolver(),
        // this will batch all calls to users.fetch that are made within 10 milliseconds.
        scheduler: windowScheduler(10),
      }),
    [country, locale],
  )
  return fetcher
}

export type UseVariantDataResultData = Record<
  string,
  EnterpriseVariantData | EnterpriseVariantDataEmpty
>
export interface UseVariantDataResult {
  data: UseVariantDataResultData
  isLoading: boolean
}

export const useVariantData = (codes: string[]): UseVariantDataResult => {
  const { locale, country } = useAppSelector((state) => state.channel)

  const fetcher = useFetcher(country ?? 'us', locale)

  const queries = useQueries({
    queries: codes.map((code) => {
      return {
        enabled: !!code,
        queryKey: [locale, country, 'variant', 'data', code],
        queryFn: () => fetcher.fetch(code),
      }
    }),
    combine: (results) => {
      // Create a map of the results
      const data: UseVariantDataResultData = {}
      for (const result of results) {
        if (result.data) {
          data[result.data.variantId] = result.data
        }
      }
      return { data, isLoading: results.some((r) => r.isLoading) }
    },
  })
  return queries
}
